import React, { useEffect, useState } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import image from 'https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg'
export default function CardDetails() {
    const { category, id } = useParams()
    const [data, setdata] = useState([])

  

    const singledata = async () => {

        try {

        const response = await axios(`http://shivraj-chavan.github.io/api/electronics/${category}/${id}.json`)
        setdata(response.data)

            
        } catch (error) {
            console.log(error)
        }
    
    }
    useEffect(() => {
        singledata()

    },[])
   


    return (
        <>
           <div class="container my-5">
        <div class="row">
            <div class="col-md-5">
                <div class="main-img">
                    <img class="img-fluid" src={data.thumbnail}  alt="ProductS"/>
                    <div class="row my-3 previews">
                        <div class="col-md-3">
                           <div > 
                            {/* <img class="w-100" src={data.images[0]} alt="Sale"/>
                            <img class="w-100" src={data.item.images[1]} alt="Sale"/>
                            <img class="w-100" src={data.item.images[2]} alt="Sale"/>
                            <img class="w-100" src={data.item.images[3]} alt="Sale"/>
                            <img class="w-100" src={data.item.images[4]} alt="Sale"/> */}
                            </div>

                        </div>
                     
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="main-description px-2">
                    <div class="category text-bold">
                        {data.category}
                    </div>
                    <div class="product-title text-bold my-3">
                        {data.title}
                    </div>


                    <div class="price-area my-4">
                        <p class="old-price mb-1"><del>$100</del> <span class="old-price-discount text-danger">(20% off)</span></p>
                        <p class="new-price text-bold mb-1">${data.price}</p>
                        <p class="text-secondary mb-1">(Additional tax may apply on checkout)</p>

                    </div>


                    <div class="buttons d-flex my-5">
                        <div class="block">
                            <a href="#" class="shadow btn custom-btn ">Wishlist</a>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="block">
                            <button class="shadow btn custom-btn">Add to cart</button>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="block quantity">
                           <form> <input type="number" class="form-control" id="cart_quantity"  min="0" max="infinite" placeholder="qty" name="cart_quantity"/></form>
                        </div>
                    </div>
                </div>

                <div class="product-details my-4">
                    <p class="details-title text-color mb-1">Product Details</p>
                    <p class="description">{data.description}</p>
                </div>
                </div>
                </div>
                </div>


        </>
    )
}
