import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from 'react-router-dom';


export default function CartItem() {
    const dispatch = useDispatch()
    const cartData= useSelector((state)=> state.cart)

  return (
<>
<li class="nav-item"><Link to="/addcart" class="nav-link" style={{color:'black'}}><ShoppingCartIcon/><span class="position-absolute top-40 start-90 translate-middle badge rounded-pill bg-danger">
    {cartData.length}
    <span class="visually-hidden">unread messages</span>
  </span></Link></li>
</>
  )
}
