import logo from './logo.svg';
import './App.css';
import Home from './page/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Products from './page/Products';
import Navbar from './components/Navbar';
import React, { useState } from 'react';
import { Login } from '@mui/icons-material';
import Categories from './page/Categories';
import MenclothApi from './components/MenclothApi';
import MenshoesApi from './components/MenshoesApi';
import SmartphoneApi from './components/SmartphoneApi';
import WomenclothApi from './components/WomenclothApi';
import WomenshoesApi from './components/WomenshoesApi';
import JwellaryApi from './components/JwellaryApi';
import Footer from './components/Footer';
import Electronics from './components/ElectronicsApi';
import ElectronicsApi from './components/ElectronicsApi';
import FurnitureApi from './components/FurnitureApi';
import HeadphoneApi from './components/HeadphoneApi';
// import Cardinfo from './components/Cardinfo';
import CardDetails from './components/CardDetails';
import Cart from './components/Cart';
import { Provider } from 'react-redux';
import store from './Redux/Store';


function App() {

  return (
    <>
    <Provider store={store}>
    <BrowserRouter>
    <Navbar/>
    <Routes>
<Route path='/' element={<Home/>}  />
<Route path='/Products' element={<Products/>}  />
{/* <Route path='/Categories' element={<Categories/>} />
<Route path='/categories/menscloth' element={<MenclothApi/>} />
<Route path='/categories/menshoes' element={<MenshoesApi/>} />
<Route path='/categories/smartphone' element={<SmartphoneApi/>} />
<Route path='/categories/womencloths' element={<WomenclothApi/>} />
<Route path='/categories/womenshoes' element={<WomenshoesApi/>}/>
<Route path='/categories/jwellary' element={<JwellaryApi/>} />
<Route path='/categories/electronics' element={<ElectronicsApi/>} />
<Route path='categories/furniture' element={<FurnitureApi/>} />
<Route path='/categories/headphone' element={<HeadphoneApi/>} /> */}
<Route path='/productinfo/:category/:id' element={<CardDetails/>} />
<Route path='/addcart/' element={<Cart/>} />
{/* <Route path='cart' element={<Cart/>} /> */}


    </Routes>
    <Footer/>
    </BrowserRouter>
    </Provider>
   
    </>
  );
}

export default App;
