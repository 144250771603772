import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
        <p className='mt-4 mb-0 text-center pt-3' style={{height:'50px', backgroundColor:'black', color:'white'}}>@ Copywrite | All Rights Reserved.</p>
      
    </div>
  )
}
