import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removecart } from '../Redux/Slice'

export default function Cart() {
    const dispatch = useDispatch()
    const cartData= useSelector((state)=> state.cart)
    
    const removeData=(cartData)=>{
        dispatch(removecart(cartData))
    }
  return (
   <section className="pb-4">
  <div className="border rounded-5">
    <section className="w-100 p-4">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-7">
                  <h5 className="mb-3"><a href="#!" className="text-body"><i className="fas fa-long-arrow-alt-left me-2" />Continue shopping</a></h5>
                  <hr />
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <p className="mb-1">Shopping cart</p>
                      <p className="mb-0">You have {cartData.length} items in your cart</p>
                    </div>
                    <div>
                      <p className="mb-0"><span className="text-muted">Sort by:</span> <a href="#!" className="text-body">price <i className="fas fa-angle-down mt-1" /></a></p>
                    </div>
                  </div>

                 {
                    cartData.map((value,index)=>{
                       return(
                        <div className="card mb-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              <div>
                                <img src={value.thumbnail} className="img-fluid rounded-3" alt="Shopping item" style={{width: 65}} />
                              </div>
                              <div className="ms-3">
                                <h5>{value.title}</h5>
                                <p className="small mb-0">{value.category}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                              <div style={{width: 50}}>
                                {/* <h5 className="fw-normal mb-0">2</h5> */}
                              </div>
                              <div style={{width: 80}}>
                                <h5 className="mb-0">$900</h5>
                              </div>
                              <a href='#' onClick={removeData} style={{color: 'red'}}><i className="fas fa-trash-alt" />remove</a>
                            </div>
                          </div>
                        </div>
                      </div>
                       )
                    })
                 }
                 
                </div>
                <div className="col-lg-5">
                  <div className="card bg-primary text-white rounded-3">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="mb-0">Card details</h5>
                        
                      </div>
                      <p className="small mb-2">Card type</p>
                      <a href="#!" type="submit" className="text-white"><i className="fab fa-cc-mastercard fa-2x me-2" /></a>
                      <a href="#!" type="submit" className="text-white"><i className="fab fa-cc-visa fa-2x me-2" /></a>
                      <a href="#!" type="submit" className="text-white"><i className="fab fa-cc-amex fa-2x me-2" /></a>
                      <a href="#!" type="submit" className="text-white"><i className="fab fa-cc-paypal fa-2x" /></a>
                      <form className="mt-4">
                        <div data-mdb-input-init className="form-outline form-white mb-4" data-mdb-input-initialized="true">
                          <input type="text" id="typeName" className="form-control form-control-lg" siez={17} placeholder="Cardholder's Name" />
                          <label className="form-label" htmlFor="typeName" style={{marginLeft: 0}}>Cardholder's Name</label>
                          <div className="form-notch"><div className="form-notch-leading" style={{width: 9}} /><div className="form-notch-middle" style={{width: '117.6px'}} /><div className="form-notch-trailing" /></div></div>
                        <div data-mdb-input-init className="form-outline form-white mb-4" data-mdb-input-initialized="true">
                          <input type="text" id="typeText" className="form-control form-control-lg" siez={17} placeholder="1234 5678 9012 3457" minLength={19} maxLength={19} />
                          <label className="form-label" htmlFor="typeText" style={{marginLeft: 0}}>Card Number</label>
                          <div className="form-notch"><div className="form-notch-leading" style={{width: 9}} /><div className="form-notch-middle" style={{width: '84.8px'}} /><div className="form-notch-trailing" /></div></div>
                        <div className="row mb-4">
                          <div className="col-md-6">
                            <div data-mdb-input-init className="form-outline form-white" data-mdb-input-initialized="true">
                              <input type="text" id="typeExp" className="form-control form-control-lg" placeholder="MM/YYYY" size={7} minLength={7} maxLength={7} />
                              <label className="form-label" htmlFor="typeExp" style={{marginLeft: 0}}>Expiration</label>
                              <div className="form-notch"><div className="form-notch-leading" style={{width: 9}} /><div className="form-notch-middle" style={{width: '64.8px'}} /><div className="form-notch-trailing" /></div></div>
                          </div>
                          <div className="col-md-6">
                            <div data-mdb-input-init className="form-outline form-white" data-mdb-input-initialized="true">
                              <input type="password" id="typeText" className="form-control form-control-lg" placeholder="●●●" size={1} minLength={3} maxLength={3} />
                              <label className="form-label" htmlFor="typeText" style={{marginLeft: 0}}>Cvv</label>
                              <div className="form-notch"><div className="form-notch-leading" style={{width: 9}} /><div className="form-notch-middle" style={{width: '30.4px'}} /><div className="form-notch-trailing" /></div></div>
                          </div>
                        </div>
                      </form>
                      <hr className="my-4" />
                      <div className="d-flex justify-content-between">
                        <p className="mb-2">Subtotal</p>
                        <p className="mb-2">$00.00</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-2">Shipping</p>
                        <p className="mb-2">$00.00</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p className="mb-2">Total(Incl. taxes)</p>
                        <p className="mb-2">$00.00</p>
                      </div>
                      <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-info btn-block btn-lg" data-mdb-button-initialized="true">
                        <div className="d-flex justify-content-between">
                          <span>$00.00</span>
                          <span>Checkout <i className="fas fa-long-arrow-alt-right ms-2" /></span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</section>

  )
}


// exports module = cartData;