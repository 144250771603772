import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import cartData from './Cart';
import { Button, Modal } from 'react-bootstrap';
import CartItem from './CartItem';

export default function Navbar() {
  
  // const theame =()=>{
  //   $(".btn").toggleClass(".theme")
  //   console.log("csv")

  // }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    
<>
<div className='nav container-fluid w-100 sticky-top bg-white' style={{boxShadow:'0 0 10px'}}>
    <header class="d-flex flex-wrap justify-content-center py-3 w-100 bg-white">
    <div class="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
      
      <p class="fs-4 text-decoration-none ms-5 "><Link to='/' style={{color:'black',fontSize:'35px'}}>Shopify</Link></p>
    </div>
    <form class="d-flex col-12 col-lg-auto mb-3 mb-lg-6 me-lg-3 pt-1" role="search">
          <input type="search" class="form-control form-control-white text-bg-white" placeholder="Search..." aria-label="Search" style={{borderRadius:0}}/>
          <button style={{border:'white'}}><SearchIcon/></button>
        </form>

    <ul class="nav nav-pills mt-2 ">
      <li class="nav-item"><Link to="/Products" href="#" class="nav-link" style={{color:'black'}}>Products</Link></li>
      {/* <li class="nav-item"><Link to="/Categories" href="#" class="nav-link" style={{color:'black'}}>Categories</Link></li> */}
     <li class="nav-item mt-1"><PersonIcon/><button className='btnnn' style={{border:'none',backgroundColor:'white'}} onClick={handleShow}> 
     Login</button></li> 
     <CartItem/>
      {/* <li class="nav-item" onClick={theame}> <button className='btn' style={{border:'none', backgroundColor:'white'}}><DarkModeIcon/></button></li> */}
    </ul>
    

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up For Free</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form class="">
          <div class="form-floating mb-3">
            <input type="email" class="form-control rounded-3" id="floatingInput" placeholder="name@example.com"/>
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control rounded-3" id="floatingPassword" placeholder="Password"/>
            <label for="floatingPassword">Password</label>
          </div>
          <button class="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Sign up</button>
          </form>
        </Modal.Body>
       
      </Modal>
  </header>

  
  
    </div>


</>
  
  
  
  
  )
}
