import React, { useState } from 'react'
import "../components/Card.css"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addcart } from '../Redux/Slice';

export default function Cardapi_1() {

    const data =

        [
            {
                "id": 1,
                "category": "watch",
                "title": "Noise Pulse 2",
                "heading": "Noise Pulse 2 Max Advanced Bluetooth Calling Smart Watch with 1.85'' TFT and 550 Nits Brightness, Smart DND, 10 Days Battery, 100 Sports Mode, Smartwatch for Men and Women - (Jade Green)",
                "description": [
                    "Massive 1.85' display: See everyday data clearly under the brightest sun on the 1.85'' TFT LCD that sports 550 nits of brightness and the highest screen-to-body ratio.",
                    "BT calling: Talk directly to your loved ones from your wrist; manage calls, access your favourite contacts and dial from the dial pad.",
                    "Tru Sync: Now connect with the world in a smart way, thanks to Tru Sync technology that ensures faster and stable connection and low power consumption."
                ],
                "price": "2,999",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/watch/1/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/1/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/1/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/1/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/1/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/watch/1/1.jpg"
            },
            {
                "id": 2,
                "category": "watch",
                "title": "Noise Newly Launched",
                "heading": "Noise Newly Launched Twist Bluetooth Calling Smart Watch with 1.38' TFT Biggest Display, Up-to 7 Days Battery, 100+ Watch Faces, IP68, Heart Rate Monitor, Sleep Tracking (Jet Black)",
                "description": [
                    "1.38” TFT display: Featuring a vibrant round display and a stylish metallic finish, the smartwatch offers a premium on-screen experience.",
                    "Tru SyncTM: Hassle-free pairing, stable connectivity and lower battery consumption combine to provide the most advanced calling experience.",
                    "Noise Buzz: Manage calls directly from your wrist. Access your call logs, make calls from the dial pad and save up to 10 favourite contacts."
                ],
                "price": "1,999",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/watch/2/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/2/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/2/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/2/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/2/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/watch/1/1.jpg"
            },
            {
                "id": 3,
                "category": "watch",
                "title": "Fire-Boltt Phoenix",
                "heading": "Fire-Boltt Phoenix Smart Watch with Bluetooth Calling 1.3,120+ Sports Modes, 240*240 PX High Res with SpO2, Heart Rate Monitoring & IP67 Rating",
                "description": [
                    "Fire-Boltt is India' No 1 Wearable Watch Brand Q122 by IDC Worldwide quarterly wearable device tracker Q122.【Bluetooth Calling Watch】- Fire-Boltt Phoenix enables you to make and receive calls directly from your watch via the built-in speaker and microphone. ",
                    "This smartwatch features a dial pad, option to access recent calls & sync your phone’s contacts.;【High Resolution Display】- Comes with a 1.3' TFT Color Full Touch Screen and a 240*240 Pixel High Resolution this watch is covered to flaunt the sleek and stylish look always.",
                    "【120+ Sports Modes】- Track each activity effectively with this smartwatch & activity tracker. Track your calories, steps and much more while you are on your fitness journey."
                ],
                "price": "1,799",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/watch/3/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/3/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/3/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/3/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/3/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/watch/3/1.jpg"
            },
            {
                "id": 4,
                "category": "watch",
                "title": "Noise Pulse Go ",
                "heading": "Noise Pulse Go Buzz Bluetooth Calling Smart Watch, 1.69' Clear Display, 550Nits,150+ Watch face, Comfort Strap, Heart Rate, Steps & Sleep Tracker, IP68, 7 Days Battery(Rose Pink)",
                "description": [
                    "This item Noise Pulse Go Buzz Bluetooth Calling Smart Watch, 1.69 Clear Display, 550Nits,150+ Watch face, Comfort Strap, Heart Rate, Steps & Sleep Tracker, IP68, 7 Days Battery(Rose Pink)",
                    "This smartwatch features a dial pad, option to access recent calls & sync your phone’s contacts.;【High Resolution Display】- Comes with a 1.3' TFT Color Full Touch Screen and a 240*240 Pixel High Resolution this watch is covered to flaunt the sleek and stylish look always.",
                    "【120+ Sports Modes】- Track each activity effectively with this smartwatch & activity tracker. Track your calories, steps and much more while you are on your fitness journey"
                ],
                "price": "1,799",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/watch/4/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/4/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/4/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/4/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/watch/4/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/watch/4/1.jpg"
            },
            {
                "id": 1,
                "category": "airdopes",
                "title": "boAt Airdopes",
                "heading": "boAt Airdopes 121v2 in-Ear True Wireless Earbuds with Upto 14 Hours Playback, 8MM Drivers, Battery Indicators, Lightweight Earbuds & Multifunction Controls (Active Black, with Mic)",
                "description": [
                    "Playback- Airdopes 121v2 offers a nonstop playback of up to 3.5H with each charge and an additional 10.5H playtime with the included charging case,Lightweight- With a dual tone finish on a lightweight ergonomic design weighing just around 4g per earbud",
                    " get ready to groove in full flow with Airdopes 121v2v2 TWS earbuds"
                ],
                "price": "1,299",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/1/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/1/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/1/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/1/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/1/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/airdopes/1/1.jpg"
            },
            {
                "id": 2,
                "category": "airdopes",
                "title": "boAt Airdopes 141",
                "heading": "boAt Airdopes 141 Bluetooth Truly Wireless in Ear Earbuds with mic, 42H Playtime, Beast Mode(Low Latency Upto 80ms) for Gaming, ENx Tech, ASAP Charge, IWP, IPX4 Water Resistance (Bold Black)",
                "description": [
                    "Playback- Enjoy an extended break on weekends with your favourite episodes on stream, virtue of a playback time of up to 42 hours including the 6 hours nonstop playtime for earbuds.",
                    " Frequency 20Hz-20KHz. Low Latency- Our BEAST mode makes these true wireless earbuds a partner in entertainment with real-time audio and low latency experience.Clear Voice Calls- It dons built-in mic on each earbud along with our ENx Environmental Noise Cancellation tech that ensures a smooth delivery of your voice via voice calls."
                ],
                "price": "1,499",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/2/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/2/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/2/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/2/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/2/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/airdopes/2/1.jpg"
            },
            {
                "id": 3,
                "category": "airdopes",
                "title": "realme TechLife Buds T100",
                "heading": "realme TechLife Buds T100 Bluetooth Truly Wireless in Ear Earbuds with mic, AI ENC for Calls, Google Fast Pair, 28 Hours Total Playback with Fast Charging and Low Latency Gaming Mode (Black)",
                "description": [
                    "AI Environment Noise Cancellation for calls",
                    "Upto 28 hrs of Total Playback | Fast charging - 10 min charge gives upto 120 min playback",
                    "10mm Dynamic Bass Driver for Real HD Sound"
                ],
                "price": "1,499",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/3/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/3/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/3/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/3/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/3/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/airdopes/3/1.jpg"
            },
            {
                "id": 4,
                "category": "airdopes",
                "title": "Jabra Elite 3",
                "heading": "Jabra Elite 3 in Ear Bluetooth Truly Wireless in Ear Earbuds, Noise Isolating with mic for Clear Calls, Rich Bass, Customizable Sound, Mono Mode - Dark Grey",
                "description": [
                    "Unique sound experience: The ergonomically designed wireless earphones offer class-leading customization options, high-quality sound with powerful bass and a battery life of up to 28 hours",
                    "Intelligent noise control: For optimal on the go listening, the Elite 3 offers unique noise isolation whilst providing on demand external noise pickup with HearThrough technology",
                    "Impeccable call quality: Our compact earbuds with classic Danish design come with four microphones for crystal-clear conversations - ensuring reliable voice pick up anywhere"
                ],
                "price": "3,999",
                "images": [
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/4/1.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/4/2.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/4/3.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/4/4.jpg",
                    "https://shivraj-chavan.github.io/api/electronics/airdopes/4/5.jpg"
                ],
                "thumbnail": "https://shivraj-chavan.github.io/api/electronics/airdopes/4/1.jpg"
            }
        ]


    const [isExpanded, setIsExpanded] = useState({index:null, state:false});

    const toggleContent = (indexs) => {
        setIsExpanded((prev)=>{
            if(prev.index === indexs && prev.state == true){
                return{index:null , state:false}

            }
            else{
                return{index:indexs, state:true}
            }
        })
    }
    const navigate= useNavigate()
    const openpage=(x)=>{
      navigate(`/productinfo/${x.category}/${x.id}`)
    }
    const dispatch=useDispatch()

    const sendData=(data)=>{
        dispatch(addcart(data))
    }

    return (
        <>
            <div className='head mt-5 '>
                <h1 className='ms-4 fw-bold'>New Arrival</h1>

            </div>
            {
                data.map((product2, indexs) => (
                        <div key={indexs} class="card mx-auto mt-3" style={{ width: "18rem" }}>
                            <img src={product2.thumbnail} class="card-img-top img-thumbnail mt-2" onClick={()=>{openpage(product2)}} alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">{product2.title.substr(0, 20)}</h5>
                                <p class="card-text">{indexs === isExpanded.index ? product2.description : `${product2.description[0].substring(0, 100)}...`}
                                    <button onClick={()=> toggleContent(indexs)} style={{border:'none', backgroundColor:'white', color:'blue'}}>
                                        {isExpanded ? 'Read More' : 'Read Less'}
                                    </button>

                                </p>
                                <a href="#" class="btn btn-primary"> $ {product2.price}</a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a onClick={()=>{sendData(product2)}} className='btn btn-outline-primary' >Add to Cart</a>
                            </div>
                        </div>
                    )

                )
            }

        </>
    )
}
