import React from 'react'
import image_1 from '../img/hero.png'
export default function Image() {
  return (
    <section className='w-100 h-auto' style={{backgroundColor:'#e3edf6'}}>
        <div className='row'>
            <div className=' col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mx-auto d-flex justify-content-center'>
              <div className='d-flex flex-column align-center justify-content-center ms-5'>
                <p>Starting at $899</p>
                <h1>The Best Notebook Collection 2024</h1>
                <h3>Exclusive Offer -10% of This Week</h3>
                <a><button>Shop Now</button></a>
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mx-auto'>
                <img className='img img-fluid' src={image_1}/>

            </div>

        </div>
    </section>
  )
}
