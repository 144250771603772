import React from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DiscountIcon from '@mui/icons-material/Discount';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
export default function Benefits() {
  return (
    <section>
        <div className='x1 ms-5 me-5'>
    <div className='row mt-5 mb-4 '>
        <div className='bg-light col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mx-auto mt-1 ' style={{border:'1px solid black'}}>
            <h3><LocalShippingIcon/><b>Free Delivery</b></h3>
            <p className='ms-4'>Orders From All items</p>
        </div>
        <div className='bg-light col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mx-auto mt-1' style={{border:'1px solid black'}}>
            <h3><MonetizationOnIcon/><b>Return & Refund</b></h3>
            <p className='ms-4'>Money Back Guarantee</p>
        </div>
        <div className='bg-light col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mx-auto mt-1' style={{border:'1px solid black'}}>
            <h3><DiscountIcon/><b>Member Discount</b></h3>
            <p className='ms-4'>On Order Over $99</p>
        </div>
        <div className='bg-light col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mx-auto mt-1' style={{border:'1px solid black'}}>
            <h3><SupportAgentIcon/><b>Support 24/7</b></h3>
            <p className='ms-4'>Call Us at Any Time</p>
        </div>
        </div>
    </div>
    </section>
  )
}
