import React from 'react'
import Navbar from '../components/Navbar'
import Products from './Products'
import Image from '../components/Image'
import Benefits from '../components/Benefits'
import CardApi from '../components/CardApi'
import Offer from '../components/Offer'
import { Card } from '@mui/material'
import Cardapi_1 from '../components/Cardapi_1'
import Footer from '../components/Footer'
import Button from '../components/Button'


export default function Home() {
  return (
    <div className='container-fluid'>
      <Image/>
      <Benefits/>
      
      <div className='row'>
        <CardApi/>
      </div>
      <Offer/>
      <div className='row'>
      <Cardapi_1/>
      </div>
    
    </div>
  )
}
