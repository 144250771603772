import React, { useEffect, useState } from 'react'
import Cardapi_2 from '../components/Cardapi_2'
import Button from '../components/Button'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addcart } from '../Redux/Slice'

export default function Products() {
  const dispatch= useDispatch()
 
  const [data,setdata]= useState([])
  const [filter,setfilterdata]= useState(data)

  const sendData=(data)=>{
    dispatch(addcart(data))
  }

  useEffect(()=>{
  const  getApiData= async()=>{

      const {data}=await axios("http://shivraj-chavan.github.io/api/electronics.json")
      setdata(data)
      setfilterdata(data)
    }
    getApiData()
  },[])

  const addfilter=(category)=>{

    if(category=="All"){
      setfilterdata(data)
    }
    else{
      let newdata = data.filter((dt)=>dt.category==category)
      setfilterdata(newdata)
    }
  }
  const [isExpanded, setIsExpanded] = useState({ index: null, state: false });
  const toggleContent = (indexs) => {
      setIsExpanded((prev)=>{
          if(prev.index === indexs  &&  prev.state == true){
             return { index: null , state: false }
          }else{
          return{
            index : indexs , state : true
          }
      }})
    }

    const navigate= useNavigate()
    const openpage=(x)=>{
      navigate(`/productinfo/${x.category}/${x.id}`)


    }

  

  return (
 <div className='container-fluid'>
  <div className='row'>
    <div className='d-flex justify-content-around'>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("All")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}} >All</button>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("mobile")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}}>Mobile</button>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("laptop")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}}>Laptop</button>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("tv")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}}>Tv</button>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("watch")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}}>Watch</button>
    <button className='mt-4 mb-4 bg-primary' onClick={()=>addfilter("airdopes")} style={{border:'none',borderRadius:'3px',height:'40px',width:'100px',color:'white'}}>Airpods</button>
    </div>
   
  </div>
     <div className='row'>
      {
      filter?.map((product, indexs) => (
        <div key={indexs} class="card mx-auto mt-2 mb-2" style={{ width: "18rem" }}>
            <img  src={product.thumbnail} class="card-img-top img-thumbnail mt-2" onClick={()=>{openpage(product)}} style={{height:"260px",width:"260px",}} alt="..." />
            <div class="card-body">
              <p>{product.category}</p>
                <h5 class="card-title">{product.title.substr(0, 20)}</h5>
                  <p class="card-text">{indexs === isExpanded.index ? product.description : `${product.description[0].substring(0, 100)}`}
                    <button onClick={() => toggleContent(indexs)} style={{border:'none', backgroundColor:'white', color:'blue'}}>
                        {isExpanded ? 'Read More' : 'Read Less'}
                    </button>

                </p>  
                <a href="#" class="btn btn-primary"> $ {product.price} </a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a onClick={()=>{sendData(product)}} class="btn btn-outline-primary"> add to cart </a>

            </div>
        </div>

    ))
      }


      
    </div>
 </div>
  )
}
