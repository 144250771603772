import React from 'react'
import image_2 from '../img/banner.jpg'

export default function Offer() {
  return (
    <section style={{backgroundColor:'#e3edf6'}}>
        <div className='offer'>
            <div className='row mt-5 '>
                <div className='d-flex col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mx-auto'>
                    <img className='img img-fluid' src={image_2} style={{width:'600px'}}/>

                </div>
                <div className='d-flex col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mx-auto align-items-center align-content-justify'>
                    <div className='mx-auto d-flex flex-column align-items-center align-content-center'>
                    <h2><b>Don't Miss The Offer</b></h2>
                    <h3><b>Grab it Now</b></h3>
                    <button className='btn btn-outline-dark'>Shop Now</button>
                    </div> 
                </div>

            </div>

        </div>
    </section>
  )
}
