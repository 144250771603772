import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function CardApi() {
    const data=
    [
        {
            "id": 1,
            "category": "mobile",
            "title": "Tecno Spark 9",
            "heading": "Tecno Spark 9 (Sky Mirror, 4GB RAM,64GB Storage) | 7GB Expandable RAM | Helio G37 Gaming Processor",
            "description": [
                "Upto 7GB larger RAM (4GB LPDDR4x + 3GB Virtual RAM) with the help of memory fusion technology | 37% improvement in apps switching | 64GB eMCP ROM | No support for Africa's frequency bands | Warranty In India Only",
                "90Hz Refresh Rate | 180Hz Touch Sampling Rate | 6.6 HD+ Dot Notch display | 269PPI Pixel Density for richer colors",
                "13MP rear Dual Camera and Dual Flashlight | 8MP Selfie camera with front flash| Multiple AI modes like Portrait, HDR, Smile Shot, AI Scene Detection | Shoot Clear 1080P Time-lapse, Slow Motion, Video Bokeh videos"
            ],
            "price": "7,999",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/mobile/1/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/1/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/1/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/1/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/1/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/mobile/1/1.jpg"
        },
        {
            "id": 2,
            "category": "mobile",
            "title": "Redmi Note 12 5G",
            "heading": "Redmi Note 12 5G Matte Black 4GB RAM 128GB ROM | 1st Phone with 120Hz Super AMOLED and Snapdragon® 4 Gen 1 | 48MP AI Triple Camera",
            "description": [
                "Display: Super AMOLED (1080x2400) Display with 120Hz Refresh rate; 1200nits peak brightness; 240Hz Touch sampling rate",
                "Processor: Snapdragon 4 Gen1 6nm Octa-core 5G processor for high performance and efficiency with Adreno 619 GPU; Up to 2.0GHz",
                "Camera: 48MP AI Triple camera setup with 8MP Ultra Wide sensor and 2MP Macro camera| 13MP Front camera"
            ],
            "price": "17,999",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/mobile/2/1.webp",
                "https://shivraj-chavan.github.io/api/electronics/mobile/2/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/2/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/2/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/2/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/mobile/2/1.webp"
        },
        {
            "id": 3,
            "category": "mobile",
            "title": "Redmi K50i 5G",
            "heading": "Redmi K50i 5G (Phantom Blue, 8GB RAM, 256GB Storage)| Flagship Mediatek Dimensity 8100 Processor | 144Hz Liquid FFS Display",
            "description": [
                "The 5G enabled Dimensity 8100 SoC built on TSMC’s advanced 5nm process technology has 25% better CPU power efficiency over previous Dimensity chips. Combined with LPDDR5 RAM and UFS 3.1 storage the device elevates the gaming experience to new heights. The device can maintain sustained peak performance owing to the better heat dissipation via a large vapor chamber cooling system with 7 layers of graphite",
                "The 6.6 FHD+ 144Hz Fluid, Dolby Vision display along with 7-stage dynamic refresh rate is capable of producing 1 billion colors. The display produces accurate, vibrant colors & is extremely smooth and responsive as well, keeping the users fully immersed in their gaming & multimedia. The device comes with a unique 20.5:9 aspect ratio, clean & sharp flat frame design making the device aesthetic and comfortable to hold even for a longer duration",
                "Reimagine photography with 64MP ISOCELL primary sensor, 8MP Ultra-Wide, and 2MP Macro triple camera setup. The triple camera setup with the AI noise reduction algorithm helps capture stellar images effortlessly both during the day and night scenarios. The 4K video recording capability brings out movie-quality videos in a hassle-free manne"
            ],
            "price": "26,999",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/mobile/3/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/3/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/3/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/3/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/3/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/mobile/3/1.jpg"
        },
        {
            "id": 4,
            "category": "mobile",
            "title": "OnePlus 11 5G",
            "heading": "OnePlus 11 5G (Titan Black, 8GB RAM, 128GB Storage)",
            "description": [
                "Camera: 50MP Main Camera with Sony IMX890 (OIS supported), 48MP Ultrawide Camera with Sony IMX581 (FOV: 115 degree) and 32MP Telephoto lens with Sony IMX709 (2X Optical Zoom); 16MP Front (Selfie) Camera with EIS support",
                "Camera Modes: Photo, Video, Night, PRO, PANO, Portrait, TIME-LAPSE, SLO-MO, Text scanner, Movie, Long Exposure, Dual-view video, TILT-SHIFT, XPAN, Scan, STICKER, and AI ID photo",
                "Display: 6.7 Inches; 120 Hz AMOLED QHD Display with Corning Gorilla Glass Victus; Resolution: 3216 X 1440 pixels; HDR 10+, sRGB, 10-bit Color Depth, PWM + DC dimming"
            ],
            "price": "56,999",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/mobile/4/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/4/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/4/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/4/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/mobile/4/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/mobile/4/1.jpg"
        },
        {
            "id": 1,
            "category": "laptop",
            "title": "ASUS VivoBook 15 ",
            "heading": "ASUS VivoBook 15 (2021), 15.6-inch (39.62 cm) HD, Dual Core Intel Celeron N4020, Thin and Light Laptop (4GB RAM/256GB SSD/Integrated Graphics/Windows 11 Home/Transparent Silver/1.8 Kg), X515MA-BR011W",
            "description": [
                "Processor: Intel Celeron N4020, 1.1 GHz base speed, Up to 2.8 GHz Turbo Speed, 2 cores, 2 Threads, 4MB Cache",
                "Memory & Storage: 4GB SO-DIMM DDR4 2400MHz RAM, Support up to 8GB using 1x SO-DIMM Slot with | Storage: 256GB M.2 NVMe PCIe SSD",
                "Display: 15.6-inch (39.62 cms), LED-Backlit LCD, HD (1366 x 768) 16:9, 220nits, NanoEdge bezel, Anti-Glare Plane with 45% NTSC, 82% Screen-To-Body Ratio"
            ],
            "price": "25,990",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/laptop/1/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/1/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/1/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/1/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/1/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/laptop/1/1.jpg"
        },
        {
            "id": 2,
            "category": "laptop",
            "title": "Acer Extensa 15 ",
            "heading": "Acer Extensa 15 Thin & Light Intel Processor Pentium Silver N5030 15.6 inches ( 39 cm ) Business Laptop (4GB RAM/256 GB SSD/Windows 11 Home/Black/1.9 Kg, EX215-31)",
            "description": [
                "Fast and Easy Computing : Perfect budget laptop under 30000 for students and working professionals that helps get jobs done quickly and efficiently thanks to the Intel Pentium Silver N5030 processor.",
                "Redefined Design and Light Weight :The latest Extensa Acer Laptop has been redesigned to be thinner and lighter than ever. With less than 20mm thin and with a weight of around 1.9kg, it can accompany you wherever you need to go",
                "Ease of Storage: 4 GB RAM Laptop with 256GB SSD helps easily store and share important projects and data"
            ],
            "price": "25,990",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/laptop/2/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/2/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/2/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/2/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/2/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/laptop/2/1.jpg"
        },
        {
            "id": 3,
            "category": "laptop",
            "title": "Microsoft Surface GO",
            "heading": "Microsoft Surface GO 3 8VA-00013 10.5' (26.67 cms) Laptop (Intel Pentium T6600 Processor/8GB/128GB SSD/Windows 11 Home), Platinum",
            "description": [
                "Processor: Intel Pentium T6600 Processor gives your daily tasks and apps an added boost",
                "Memory and Storage: 8GB LPDDR3 RAM | 128GB SSD",
                "Display: 10.5 inch touch screen; 4:3 Aspect Ratio | 1920 x 1280 pixels | 1500:1 contrast ratio"
            ],
            "price": "59,999",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/laptop/3/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/3/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/3/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/3/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/3/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/laptop/3/1.jpg"
        },
        {
            "id": 4,
            "category": "laptop",
            "title": "Infinix INBook X1",
            "heading": "Infinix INBook X1 Neo Series Celeron Quad Core - (Intel, 4 GB/128 GB SSD/Windows 11 Home) XL22 Thin and Light Laptop (14 inch (35 cm) Cosmic Blue, 1.24 kg)",
            "description": [
                "PROCESSOR : Intel Celeron Quad Core N5100 (Base Frequency 1.1 GHz, Max Turbo Frequency at 2.8 GHz)",
                "RAM : 4GB LPDDR4X 2933 MHz RAM",
                "STORAGE : 128 GB SSD NVMe PCIE 3.0"
            ],
            "price": "22,990",
            "images": [
                "https://shivraj-chavan.github.io/api/electronics/laptop/4/1.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/4/2.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/4/3.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/4/4.jpg",
                "https://shivraj-chavan.github.io/api/electronics/laptop/4/5.jpg"
            ],
            "thumbnail": "https://shivraj-chavan.github.io/api/electronics/laptop/4/1.jpg"
        }
    ]


    const [isExpanded, setIsExpanded] = useState({index:null , state:false});
    
    const toggleContent = (indexs) => {
        setIsExpanded((prev)=>{
            if(prev.index === indexs && prev.state == true){
                return{index: null, state: false}
            }
            else{
                return{index: indexs, state:true}
            }

        })
    }
    const navigate= useNavigate()
    const openpage=(x)=>{
      navigate(`/productinfo/${x.category}/${x.id}`)
    }
    
  return (
    <>
    <div className='text mt-4'>
        <h1 className='ms-4 fw-bold'>Top Trendings</h1>

    </div>
    {
       data.map((product1,indexs)=>(
            <div key={indexs} class="card mx-auto mt-4" style={{width: "18rem"}}>
 <img src={product1.thumbnail} class="card-img-top img-thumbnail mt-2" onClick={()=>{openpage(product1)}} style={{height:"260px",width:"260px",}} alt="..." />  <div class="card-body">
    <h5 class="card-title">{product1.title.substr(0,20)}</h5>
    <p class="card-text">{indexs === isExpanded.index ? product1.description : `${product1.description[0].substring(0, 100)}...`}


    <button 
                onClick={() => toggleContent(indexs)} 
                style={{ border: 'none', backgroundColor: 'white', color: 'blue' }}>
                {isExpanded ? 'Read more' : 'Read less'}
            </button>
           

    </p>    
    <a href="#" class="btn btn-primary"> $ {product1.price}</a>&nbsp;&nbsp;&nbsp;&nbsp;
    <a className='btn btn-outline-primary' >Add to Cart</a>


  </div>
</div>
        )
       )
    }
    </>
  )
    }
